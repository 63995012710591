import React from 'react';
import { Link } from 'react-router-dom';
import {
    buildClassNames,
    mergeClassNames,
} from '../../../utils/classNameUtils';
import Tooltip from '../../TooltipIcon/Tooltip';
import Loader, { LoaderSize } from '../../loaders/Loader';
import styles from './iconButton.module.scss';
import {
    Asset,
    getAssetSrc,
} from '../../../utils/getAssetSrc';

export type IconButtonSize = 'small' | 'normal' | 'large' | 'extra-large';

export interface IconButtonProps {
    icon: Asset | React.ReactNode | JSX.Element;
    href?: string;
    external?: boolean;
    disabled?: boolean;
    size?: IconButtonSize;
    margin?: 'left'|'right';
    hint?: string;
    loading?: boolean;
    iconClassName?: string;
    containerClassName?: string;
    testId?: string;
    onClick?: (e: React.MouseEvent) => void;
    withBackground?: boolean;
}

function getInnerContent(icon: IconButtonProps['icon']) {
    const src = getAssetSrc(icon);

    if (typeof src !== 'undefined') {
        return <img className={styles['button__icon']} src={src} alt="" />;
    }

    return icon as React.ReactNode;
}

const IconButton: React.FunctionComponent<IconButtonProps> = props => {
    const {
        icon,
        href,
        external,
        disabled,
        loading,
        size = 'normal',
        margin = 'right',
        hint,
        iconClassName,
        onClick,
        containerClassName,
        withBackground,
    } = props;

    const buttonClassNames = mergeClassNames([
        buildClassNames(styles, [
            'button',
            `button--${size}`,
            `button--margin-${margin}`,
            withBackground && 'button--with-background',
            loading && 'button--loading',
            disabled && 'button--disabled',
            margin === 'left' ? 'button--margin-left' : null,
        ]),
        containerClassName,
    ]);

    const iconContainerClassNames = mergeClassNames([
        styles['button__icon-container'],
        iconClassName,
    ]);

    function getLoaderSize(): LoaderSize {
        switch (props.size || 'normal') {
        case 'extra-large':
        case 'large':
            return 'normal';
        case 'normal':
        case 'small':
            return 'small';
        default:
            return 'small';
        }
    }

    const innerContent = (
        <span className={iconContainerClassNames}>
            {props.loading && <Loader size={getLoaderSize()} spinnerType="three-sides-circle" />}
            {!props.loading && getInnerContent(icon)}
        </span>
    );

    let innerControl: JSX.Element;

    if (href) {
        innerControl = external
            ? (
                <a
                    className={buttonClassNames}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid={props.testId}
                    onClick={onClick}
                >
                    {innerContent}
                </a>
            )
            : (
                <Link
                    to={href}
                    className={buttonClassNames}
                    type="button"
                    data-testid={props.testId}
                    onClick={onClick}
                >
                    {innerContent}
                </Link>
            );
    } else {
        innerControl = (
            <button
                className={buttonClassNames}
                type="button"
                disabled={disabled}
                data-testid={props.testId}
                onClick={e => onClick && onClick(e)}
            >
                {innerContent}
            </button>
        );
    }

    if (hint && hint.length > 0) {
        return (
            <Tooltip title={hint}>
                {innerControl}
            </Tooltip>
        );
    }

    return innerControl;
};

export default IconButton;