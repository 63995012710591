import { useSelector } from 'react-redux';
import { IObjectHandler } from '../ducks/object';
import { useActions } from './useActions';

type HookReturnType<TState> = [
    TState,
    IObjectHandler<TState>['update']
];

/**
 * @deprecated
 * Используйте @see {@link storeHandler} и @see {@link useStoreHandler}.
 *
 * Хук для управления общего состояния созданного с помощью {@link objectHandler} внутри компонента.
 *
 * Работает аналогичным образом, как и React useState, но умеет обновлять состояние частично.
 *
 * @param handler Хендлер реализующий {@link IObjectHandler}
 * @returns Возвращает состояние и метод для его частичного изменения
 * @category Hooks
 *
 * @see {@link objectHandler}
 * @see {@link useStoreHandler}
 *
 * @example
 * const [
 *      // Состояние
 *      appState,
 *      // Функция для обновления состояния
 *      updateAppState
 *  ] = useObjectHandler(sharedAppStateHandler);
 *
 * didReminderClicked = useCallback(() => {
 *      updateAppState({
 *          // Обновляем только 1 поле в состоянии
 *          reminderLastClickDate: moment().toISODate(),
 *      });
 * }, [ updateAppState ]);
 *
 * didResetClicked = useCallback(() => {
 *      // Передавайте undefined, чтобы сбросить состояние к начальному виду
 *      updateAppState(undefined);
 * }, [ updateAppState ]);
 */
export default function useObjectHandler<TState>(handler: IObjectHandler<TState>): HookReturnType<TState> {
    const state = useSelector(handler.selector);

    const updateState = useActions(handler.update);

    return [
        state,
        updateState,
    ];
}
