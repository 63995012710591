'use client';

import React, { useCallback } from 'react';
import {
    BaseFieldProps,
    Field,
    WrappedFieldProps,
} from 'redux-form';
import { withWrapperDisplayName } from '@core/utils/buildDisplayName';
import {
    IConnectableInputProps,
    IReadableInputProps,
} from './common';

export interface IWrapperFieldProps {
    /**
     * @event
     * Событие, которое срабатывает до фактического изменения формы.
     * Если событие возвращает `true`, то значение не сохраняется в форме.
     *
     * @param newValue Новое значение, которое необходимо сохранить.
     * @param previousValue Текущее значение поля.
     * @param name Имя поля в форме.
     * @returns Возвращает флаг прерывания сохранения. Если вернуть `true` то значение не будет сохранено в форме.
     *
     * @example
     *
     * const didBeforeSwitchChanged = (value: boolean, prevValue: boolean) => {
     *      if (!value) {
     *          // Показываем модальное окно подтверждения изменения поля
     *          // и не сохраняем новое значение.
     *          showConfirmationModal();
     *          return true;
     *      }
     *
     *      // В остальных случаях сохраняем без доп действий
     * }
     *
     * return (
     *    <SwitchField name="enabled" onBeforeChange={didBeforeSwitchChanged} />
     * );
     */
    onBeforeChange?: (newValue: any, previousValue: any, name: string) => boolean;
    /**
     * Значение по-умолчанию, которое будет передавать в компонент, если в форме было сохранено `null` или `undefined`.
     */
    defaultValue?: any;
}

export type FieldProps = BaseFieldProps & IWrapperFieldProps;

export function wrapToFieldComponent<TProps extends IConnectableInputProps>(InputControl: React.ComponentType<TProps>) {
    const Wrapper: React.FC<WrappedFieldProps & IWrapperFieldProps & Omit<TProps, keyof IConnectableInputProps>> = props => {
        const {
            input,
            meta,
            onBeforeChange,
            defaultValue,
            ...rest
        } = props;

        const didChanged = useCallback((eventOrValue) => {
            if (onBeforeChange) {
                const prevented = onBeforeChange(eventOrValue, input.value, input.name);
                if (prevented) {
                    return;
                }
            }

            input.onChange(eventOrValue);
        }, [ input.value, input.onChange, onBeforeChange ]);

        // TODO Fix typings
        const AnyInputControl = InputControl as any;
        return (
            <AnyInputControl
                {...rest}
                name={input.name}
                {...meta}
                value={input.value ?? defaultValue}
                onChange={didChanged}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
            />
        );
    };

    return Wrapper;
}

/**
 * Подключает компонент, реализующий свойства {@link IConnectableInputProps} к любой форме.
 * Расширяет компонент новыми пропсами {@link IWrapperFieldProps}.
 *
 * @param InputControl - компонент, реализующий свойства {@link IConnectableInputProps} для подключения к полю формы.
 * @returns Новый компонент, с возможностью подключить компонент редактора к определенному полю формы.
 */
export function connectToField<TProps extends IConnectableInputProps>(InputControl: React.ComponentType<TProps>) {
    const WrappedInputComponent = wrapToFieldComponent(InputControl);

    const FieldWrapper: React.FC<Omit<BaseFieldProps<TProps>, 'component'> & Omit<TProps, keyof IConnectableInputProps> & IWrapperFieldProps> = props => (
        <Field {...props} component={WrappedInputComponent} />
    );

    return withWrapperDisplayName(FieldWrapper, 'connectToField', InputControl);
}

/**
 * Подключает компонент, реализующий свойства {@link IConnectableInputProps} к любой форме.
 *
 * @param InputControl
 * @returns Новый компонент, с возможностью подключить компонент для чтения значения формы из определенного поля.
 */
export function connectToReadField<TProps extends IReadableInputProps>(InputControl: React.ComponentType<TProps>) {
    const WrappedInputComponent = wrapToFieldComponent(InputControl);

    const FieldWrapper: React.FC<Omit<BaseFieldProps<TProps>, 'component'> & Omit<TProps, keyof IConnectableInputProps>> = props => (
        <Field {...props} component={WrappedInputComponent} />
    );

    return withWrapperDisplayName(FieldWrapper, 'connectToReadField', InputControl);
}
